import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import CS from "metabase/css/core/index.css";
import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
    fill: PropTypes.string,
  };

  render() {
    const { dark, height, width, fill = "currentcolor" } = this.props;
    return (
      <svg
        className={cx(
          "Icon",
          { [CS.textBrand]: !dark },
          { [CS.textWhite]: dark },
        )}
        viewBox="0 0 66 85"
        width={width}
        height={height}
        fill={fill}
        data-testid="main-logo"
      >
        <defs
          id="defs1" />
        <g
          id="g1"
          transform="translate(-75.985001,-90.833336)">
          <title
            id="title1">Layer 1</title>
          <g
            id="svg_5"
            class="icon-text-wrapper icon-svg-group iconsvg"
            fill="#333333">
            <g
              id="svg_6"
              class="iconsvg-imagesvg">
              <g
                id="svg_7"
                transform="translate(70,85.833333)">
                <rect
                  id="svg_8"
                  class="image-rect"
                  height="60"
                  width="60"
                  y="5"
                  x="5.9850001"
                  stroke-width="2"
                  fill-opacity="0"
                  fill="#333333" />
                <svg
                  id="svg_9"
                  class="image-svg-svg primary"
                  filtersec="colorsb8518766203"
                  height="60"
                  width="60"
                  y="5"
                  x="5.9850001"
                  version="1.1">
                  <svg
                    id="svg_10"
                    viewBox="0.10130927 0.1013111 99.898689 99.898689"
                    version="1.1"
                    width="100%"
                    height="100%">
                    <path
                      id="svg_11"
                      fill="#39b54a"
                      d="M 46.92,53.18 H 25.14 a 3.13,3.13 0 0 1 0,-6.26 H 46.92 V 25.14 a 3.13,3.13 0 0 1 6.26,0 V 46.92 H 100 A 50,50 0 1 0 46.92,100 Z" />
                    <path
                      id="svg_12"
                      fill="#009245"
                      d="M 53.18,53.18 V 100 A 50,50 0 0 0 100,53.18 Z" />
                  </svg>
                </svg>
              </g>
            </g>
            <g
              id="svg_13"
              transform="translate(15.833334,-90)">
              <g
                id="svg_14"
                class="tp-name iconsvg-namesvg"
                data-gra="path-name">
                <g
                  id="svg_15"
                  transform="translate(75.985,95)">
                  <g
                    id="svg_16">
                    <path
                      id="svg_17"
                      d="m 23.52,-72.47 h 7.8 v 32.56 h -7.8 v -16.25 q 0,-3.9 -2.73,-6.63 v 0 q -2.73,-2.73 -6.63,-2.73 v 0 q -1.89,0 -3.61,0.72 -1.72,0.71 -3.02,2.01 v 0 q -2.73,2.73 -2.73,6.63 v 0 q 0,3.83 2.73,6.56 v 0 q 1.3,1.37 3.02,2.05 1.72,0.68 3.61,0.68 v 0 q 1.95,0 3.51,-0.65 v 0 l 2.8,6.96 q -2.99,1.49 -6.96,1.49 v 0 q -3.57,0 -6.6,-1.33 -3.02,-1.33 -5.23,-3.67 -2.21,-2.34 -3.44,-5.46 Q -3,-52.65 -3,-56.16 v 0 q 0,-3.57 1.24,-6.7 1.23,-3.12 3.44,-5.46 2.21,-2.34 5.23,-3.67 3.03,-1.33 6.6,-1.33 v 0 q 3.19,0 5.65,0.97 2.48,0.98 4.36,2.48 v 0 z m 38.74,0.12 v 0 5.27 L 46.6,-47.71 h 14.88 v 7.8 H 40.29 v 0 h -5.72 v -5.27 L 50.24,-64.61 H 35.93 v -7.8 h 20.81 v 0.06 z m 17.88,6.83 v 0 q -3.91,0 -6.64,2.73 v 0 q -2.73,2.73 -2.73,6.63 v 0 q 0,3.83 2.73,6.56 v 0 q 1.3,1.37 3.03,2.05 1.72,0.68 3.61,0.68 v 0 q 1.88,0 3.57,-0.68 1.69,-0.68 3.05,-2.05 v 0 q 2.73,-2.73 2.73,-6.56 v 0 q 0,-3.9 -2.73,-6.63 v 0 q -2.86,-2.73 -6.62,-2.73 z m 0,-7.8 v 0 q 3.57,0 6.69,1.33 3.12,1.33 5.46,3.67 2.34,2.34 3.67,5.46 1.33,3.13 1.33,6.7 v 0 q 0,3.51 -1.33,6.63 -1.33,3.12 -3.67,5.46 -2.34,2.34 -5.46,3.67 -3.12,1.33 -6.69,1.33 v 0 q -3.58,0 -6.7,-1.33 -3.12,-1.33 -5.46,-3.67 -2.34,-2.34 -3.67,-5.46 -1.34,-3.12 -1.34,-6.63 v 0 q 0,-3.57 1.34,-6.7 1.33,-3.12 3.67,-5.46 2.34,-2.34 5.46,-3.67 3.12,-1.33 6.7,-1.33 z m 35.94,25.61 1.95,7.8 h -7.15 q -2.21,0 -4.16,-0.84 -1.95,-0.85 -3.38,-2.31 -1.43,-1.47 -2.28,-3.42 -0.84,-1.95 -0.84,-4.15 v 0 -28.35 l 7.8,-1.95 v 12.42 h 4.55 l 1.95,7.8 h -6.5 v 10.08 q 0,1.23 0.84,2.08 0.85,0.84 2.02,0.84 v 0 z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
